import React, { useState, useEffect } from 'react';
import Header from './Header'; // Импортируйте компоненты таким образом
import Content1 from './Content1';
import Content2 from './Content2';
import Content3 from './Content3';
import Content4 from './Content4';
import ButtonUp from './ButtonUp';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [isButtonVisible, setButtonVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setButtonVisible(true);
      } else {
        setButtonVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <header id="section-header" className="App-header h-100">
        <Header/>
      </header>
      <main className="App-content">
        <Content1/>
        <Content2/>
        <Content3/>
        <Content4/>
      </main>
      <div className={`App-up ${isButtonVisible ? 'visible' : ''}`}>
        <a href='#section-header' className='h-100'><ButtonUp/></a>
      </div>
    </div>
  );
}

export default App;
