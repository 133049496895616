import React from 'react';

function ButtonUp() {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      Вверх
    </div>
  );
}

export default ButtonUp;