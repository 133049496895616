import React, { useState } from 'react';
function Header() {
    const phoneNumber = "+79216126611";
    const email = "support@remontuvi.ru";
    const [copyMessageTelVisible, setCopyMessageTelVisible] = useState(false);
    const [copyMessageEmailVisible, setCopyMessageEmailVisible] = useState(false);

    const handleCopyClick = () => {
        const tempTextarea = document.createElement("textarea");
        tempTextarea.value = phoneNumber;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();
        tempTextarea.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(tempTextarea);

        setCopyMessageTelVisible(true);
        setTimeout(() => {
        setCopyMessageTelVisible(false);
        }, 2000);
    };

    const handleEmailClick = async () => {
        const tempTextarea = document.createElement("textarea");
        tempTextarea.value = email;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();
        tempTextarea.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(tempTextarea);

        setCopyMessageEmailVisible(true);
        setTimeout(() => {
            setCopyMessageEmailVisible(false);
        }, 2000);
        await new Promise(resolve => setTimeout(resolve, 100));
    
        window.location.href = 'mailto:' + email;
    }

    return ( 
        <div className="header">
            {copyMessageTelVisible && <p style={{ fontSize: '18px' }} className='message-copy-phone'>Номер телефона скопирован</p>}
            {copyMessageEmailVisible && <p style={{ fontSize: '18px' }} className='message-copy-phone'>Почта скопирована</p>}
            <div className="container-fluid">
               <div className='row'>
                   <div className='col-12'>
                        <div className='row'>
                            <div className='col-2'>
                                <div className='main-logo text-center'>
                                    <img className="imglogo" src="/img/logo.png" loading="lazy" alt=""/>
                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='mainmenu'> 
                                            <nav>
                                                <ul className="menu">
                                                    <li><a className='text-menu' href="#section-about">О нас</a></li>
                                                    <li><a className='text-menu' href="#services">Услуги</a></li>
                                                    <li><a className='text-menu' href="#contract">Договор</a></li>
                                                    <li><a className='text-menu' href="#contacts">Контакты</a></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='header-contacts'>
                                            <div className='mya' onClick={handleEmailClick}>
                                                <img className="imgtel" src="/img/email.png" loading="lazy" alt=""/>
                                                <p className='text-tel contacts'>support@remontuvi.ru</p>
                                            </div>
                                            <div className='mya' onClick={handleCopyClick}>
                                            <img className="imgtel" src="/img/telnum.png" loading="lazy" alt=""/>
                                            <p className='text-tel contacts'>+7 921 612-66-11</p>
                                            </div>
                                            <a href='#' style={{display: "none"}}>
                                                {/*https://api.whatsapp.com/send?phone=79216126611&amp;text=Здравствуйте,%20у%20меня%20есть%20вопрос." target="_blank"
                                                https://teleg.run/Promaster39*/}
                                            <img className="imgwhatsapp" src="/img/whatsapp.png" loading="lazy" alt=""/>
                                            </a>
                                            <a href='#' style={{display: "none"}}>
                                            <img className="imgtelegramm" src="/img/telegram.png" loading="lazy" alt=""/>
                                            </a>
                                            <a href='#' style={{display: "none"}}>
                                            <img className="imgviber" src="/img/viber.png" loading="lazy" alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 text-center'>
                                        <div className='header-title'>
                                            <div className='header-text'>
                                                <p className='relief-text'>Качественный ремонт с гарантией</p>
                                                <p className='relief-text'>В Калининграде и области</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </div>
               </div>
            </div>
        </div>
    );
}

export default Header;