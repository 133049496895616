import React from 'react';
import CaruselComponent from './SquareCarousel';
function Content1() {
  return (
    <div className='about-content' id="section-about">
      <div className='row'>
        <div className='col-md-5 col-lg-6'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='head-about text-center'>О нас</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <p className='text-about'>
                <span class="red-indent">
                  Компания «ЮВИ» основана в 2015 году. За эти годы мы успешно отремонтировали более 500 объектов разной сложности.
                </span><br/>
                <span class="red-indent">
                  Вы можете посетить строящийся или сданный объект, с ремонтом разной сложности, гостиницы, апартаменты, квартиры или дома, 
                  что бы убедиться в качестве нашей работы!
                </span><br/>
                <span class="red-indent">
                  Накопленный опыт, высококвалифицированные специалисты, 
                  соблюдение всех норм СНиПов позволяют нам предоставить гарантию до 5 лет на выполненные работы. 
                </span><br/>
                <span class="red-indent">
                  Мы всегда несем ответственность за качество и сроки выполнения работ.
                </span>
                </p>
            </div>
          </div>
        </div>
        <div className='col-md-7 col-lg-6'>
          <CaruselComponent />
        </div>
      </div>
    </div>
  );
}

export default Content1;