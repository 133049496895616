import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PrivacyForm from './PrivacyForm';

function FeedbackForm({ onClose, topicmsg }) {
  const [privacyFormVisible, setPrivacyFormVisible] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [fieldsFilled, setFieldsFilled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
    checkFieldsFilled();
  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
    checkFieldsFilled(); 
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    checkFieldsFilled(); 
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    checkFieldsFilled(); 
  };

  const handleAgreementChange = () => {
    setIsAgreed(!isAgreed);
  };

  const handlePrivacyClick = () => { 
    setPrivacyFormVisible(true);
  };

  const checkFieldsFilled = () => {
    if (phone.trim() !== '' || email.trim() !== '') {
      setFieldsFilled(true);
    } else {
      setFieldsFilled(false);
    }
  };

  const handleSubmit = async () => {
    if (!isAgreed || !fieldsFilled) {
      // Обработка невалидной формы
      return;
    }

    setIsSubmitting(true);
    setError(''); // Сбрасываем предыдущие ошибки

    const data = {
      name: name,
      phone: phone,
      email: email,
      message: message,
      topic: topicmsg,
    };

    try {
      const response = await fetch('/send-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        console.log('Данные успешно отправлены');
        // Очистка полей после успешной отправки
        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
        setIsAgreed(false);
        onClose();
      } else {
        try {
            const errorData = await response.json(); // Попытка получить JSON-данные об ошибке
            console.error('Ошибка при отправке данных:', errorData.error);
            console.error('Детали:', errorData.details);
            setError('Не удалось отправить данные, попробуйте еще раз');
        } catch (error) {
            console.error('Ошибка при отправке данных:', response.statusText);
            setError('Не удалось отправить данные, попробуйте еще раз');
        }
      }
    } catch (error) {
      console.error('Произошла ошибка', error);
      setError('Не удалось отправить данные, попробуйте еще раз');
    } finally {
        setIsSubmitting(false);
    }
   
  };

  useEffect(() => {
    if (privacyFormVisible) {
      document.body.style.overflow = 'hidden';
      const privacyFormContainer = document.createElement('div');
      privacyFormContainer.id = 'privacy-form-container';
      document.body.appendChild(privacyFormContainer);
      ReactDOM.render(<PrivacyForm onClose={() => setPrivacyFormVisible(false)} />, privacyFormContainer);
    } else {
      const privacyFormContainer = document.getElementById('privacy-form-container');
      if (privacyFormContainer) {
        ReactDOM.unmountComponentAtNode(privacyFormContainer);
        document.body.removeChild(privacyFormContainer);
      }
      document.body.style.overflow = 'auto';
    }
  }, [privacyFormVisible]);


  const handleCancel = () => {
    // Очистка полей при отмене
    setPhone('');
    setEmail('');
    setMessage('');
    setIsAgreed(false);
    onClose();
  };

  return (
    <div className="feedback-form-modal">
      <div className='row'>
        <div className='col-lg-3 col-xl-3 col-md-12 col-sm-12'>
            <label>Как к вам обращаться:</label>
        </div>
        <div className='col-lg-9 col-xl-9 col-md-12 col-sm-12'>
            <input type="text" className="form-control" value={name} onChange={handleNameChange} />
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-3 col-xl-3 col-md-12 col-sm-12'>
            <label>Телефон:</label>
        </div>
        <div className='col-lg-9 col-xl-9 col-md-12 col-sm-12'>
            <input type="text" className="form-control" value={phone} onChange={handlePhoneChange} />
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-3 col-xl-3 col-md-12 col-sm-12'>
            <label>Email:</label>
        </div>
        <div className='col-lg-9 col-xl-9 col-md-12 col-sm-12'>
            <input type="email" className="form-control" value={email} onChange={handleEmailChange} />
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-3 col-xl-3 col-md-12 col-sm-12'>
            <label>Обращение:</label>
        </div>
        <div className='col-lg-9 col-xl-9 col-md-12 col-sm-12'>
            <textarea className="form-control" value={message} onChange={handleMessageChange} />
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-3 col-xl-3 col-md-12 col-sm-12'>
            <input type="checkbox" className='form-check' checked={isAgreed} onChange={handleAgreementChange} />
        </div>
        <div className='col-lg-9 col-xl-9 col-md-12 col-sm-12'>
            <span>Согласены с условиями политики обработки персональных данных?</span>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
            <p className='privacy-policy' onClick={handlePrivacyClick}>Политика конфиденциальности</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
            <button className='btn btn-dark btn-block w-100' onClick={handleSubmit} disabled={!isAgreed || !fieldsFilled || isSubmitting}>
                {isSubmitting ? 'Отправка...' : 'Отправить'}
            </button>
        </div>
        <div className='col-6'>
            <button className='btn btn-dark btn-block w-100' onClick={handleCancel} disabled={isSubmitting}>Отменить</button>
        </div>
      </div>
      {error && <p className='text-danger text-center'>{error}</p>}
    </div>
  );
}

export default FeedbackForm;