import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './SquareCarousel.css'; // Создайте файл стилей

const SquareCarousel = () => {
    const images = [
        '/img/2R3A7424.jpg',
        '/img/2R3A7340.jpg',
        '/img/2R3A7391.jpg',
        '/img/2R3A8127.jpg',
        '/img/2R3A8076.jpg',
        '/img/2R3A7688.jpg',
        '/img/2R3A7859.jpg',
        '/img/2R3A8139.jpg',
        '/img/2R3A7883.jpg'
        // Добавьте свои изображения
    ];

    return (
        <Carousel className="square-carousel">
            {images.map((image, index) => (
                <Carousel.Item key={index}>
                    <div className="square-image" style={{ backgroundImage: `url(${image})` }} />
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default SquareCarousel;