import React from 'react';

function Content3() {
  return (
    <div id="contract" className='contract'>
      <div className='row'>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <p className='text-h-contract'>Замер</p>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <img className='img-contract' src='/img/zamer.png'/>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <p className='text-t-contract'>
                После связи с нами, наш специалист бесплатно прибудет к Вам для выполнения замеров и оценки
                </p>
              </div>
            </div>
        </div>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-contract' src='/img/runwork.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-h-contract'>Договор</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <p className='text-t-contract'>
                После проведения замеров, мы подготовим смету, согласуем с вами стоимость и уточним сроки выполнения работ
                </p>
              </div>
            </div>
        </div>
        <div className='row'>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <p className='text-h-contract'>Выполнение работ</p>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <img className='img-contract' src='/img/contract.png'/>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <p className='text-t-contract'>
                Мы доставим необходимые материалы и проведем работы по ремонту и отделке в соответствии с заключенным договором
                </p>
              </div>
            </div>
        </div>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-contract' src='/img/endwork.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-h-contract'>Приемка</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <p className='text-t-contract'>
                Вы принимаете качественный ремонт с гарантией на выполненные работы
                </p>
              </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Content3;