import React, { useState} from 'react';
import ReactDOM from 'react-dom';
import FeedbackForm from './FeedbackForm';

function Content2() {
  const [feedbackFormVisible, setFeedbackFormVisible] = useState(false);
  const [topicmsg, setTopicmsg] = useState("общий вопрос");
  const handleFeedbackFormClick = (event) => {
    const textServiceElement = event.currentTarget.querySelector('.text-service');
    const newTopicmsg = textServiceElement.textContent;
    setTopicmsg(newTopicmsg);
    setFeedbackFormVisible(true);
  };

  return (
    <div className='services-content' id="services">
      <div className='row'>
        <div className='col-12'>
          <h1 className='head-services text-center'>Мы предоставляем полный комплект услуг</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/otdelca-works.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Отделочные работы</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/electick_work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Электромонтаж</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/malar-work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Малярные работы</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/demontag-work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Демонтаж стен</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/santeh-work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Сантехнические работы</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/radiator-work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Замена радиаторов отопления</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/teplpol-work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Устройство теплого пола</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/polpokr-work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Укладка напольных покрытий</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/dorinstall-work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Установка межкомнатных и входных дверей</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/potolok-work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Натяжные потолки</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/wolgips-work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Возведение гипсокартонных конструкций</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12' onClick={handleFeedbackFormClick}>
          <div className='service'>
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <img className='img-service' src='/img/material-work.png'/>
              </div>
              <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 d-flex text-center justify-content-center'>
                <p className='text-service'>Снабжение строительными материалами</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <p className='text-service1'>С нами возможно дистанционное ведение ремонта! Если вы находитесь в другом городе, мы постоянно на связи. Регулярные фото/видео отчеты о проделанной работе. Контроль доставки и приемки материалов заказчика. Клининг по окончанию ремонтных работ.</p>
        </div>
      </div>
      {feedbackFormVisible && (
        <div className="feedback-form-overlay">
          <FeedbackForm onClose={() => setFeedbackFormVisible(false)} topicmsg={topicmsg} />
        </div>
      )}
    </div>
  );
}

export default Content2;