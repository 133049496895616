import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PrivacyForm from './PrivacyForm';
import FeedbackForm from './FeedbackForm';

function Content4() {
  const phoneNumber = "+79216126611";
  const email = "support@remontuvi.ru";
  const [copyMessageTelVisible, setCopyMessageTelVisible] = useState(false);
  const [copyMessageEmailVisible, setCopyMessageEmailVisible] = useState(false);
  const [privacyFormVisible, setPrivacyFormVisible] = useState(false);
  const [feedbackFormVisible, setFeedbackFormVisible] = useState(false);

  const handleCopyClick = () => {
      const tempTextarea = document.createElement("textarea");
      tempTextarea.value = phoneNumber;
      document.body.appendChild(tempTextarea);
      tempTextarea.select();
      tempTextarea.setSelectionRange(0, 99999);
      document.execCommand("copy");
      document.body.removeChild(tempTextarea);

      setCopyMessageTelVisible(true);
      setTimeout(() => {
      setCopyMessageTelVisible(false);
      }, 2000);
  };

  const handleEmailClick = async () => {
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = email;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    tempTextarea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(tempTextarea);

    setCopyMessageEmailVisible(true);
    setTimeout(() => {
        setCopyMessageEmailVisible(false);
    }, 2000);
    await new Promise(resolve => setTimeout(resolve, 100));

    window.location.href = 'mailto:' + email;
}

  const handlePrivacyClick = () => { 
    setPrivacyFormVisible(true);
  };

  const handleFeedbackFormClick = () => {
    setFeedbackFormVisible(true);
  };

  useEffect(() => {
    if (privacyFormVisible) {
      document.body.style.overflow = 'hidden';
      const privacyFormContainer = document.createElement('div');
      privacyFormContainer.id = 'privacy-form-container';
      document.body.appendChild(privacyFormContainer);
      ReactDOM.render(<PrivacyForm onClose={() => setPrivacyFormVisible(false)} />, privacyFormContainer);
    } else {
      const privacyFormContainer = document.getElementById('privacy-form-container');
      if (privacyFormContainer) {
        ReactDOM.unmountComponentAtNode(privacyFormContainer);
        document.body.removeChild(privacyFormContainer);
      }
      document.body.style.overflow = 'auto';
    }
  }, [privacyFormVisible]);

  return (
    <div>
      <div id="contacts" className='contacts-footer'>
          {copyMessageTelVisible && <p style={{ fontSize: '18px' }} className='message-copy-phone'>Номер телефона скопирован</p>}
          {copyMessageEmailVisible && <p style={{ fontSize: '18px' }} className='message-copy-phone'>Почта скопирована</p>}
        <div className='row'>
            <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'>
              <div className="row">
                <div className="col-12 d-flex text-center justify-content-center">
                  <p className='contacts-h-text'>Нужна консультация или у вас есть вопросы звоните, либо воспользуйтесь формой обратной связи</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex text-center justify-content-center">
                  <p className='privacy-policy' onClick={handlePrivacyClick}>Политика конфиденциальности</p>
                </div>
              </div>
              <div className="row d-flex text-center justify-content-center">
                <div className="col-12 d-flex text-center justify-content-center">
                    <button className="btnfeedback btn btn-dark btn-block w-50" onClick={handleFeedbackFormClick}>Оставить заявку</button>
                </div>
              </div>
              <div className="row d-flex text-center justify-content-center">
                <div className="col-12 text-center justify-content-center">
                  <p className='contacts-h-text'>Качественный ремонт с гарантией</p>
                  <p className='contacts-h-text'>В Калининграде и области</p>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'>
              <div className='contacts-form'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='contacts-telefon'  onClick={handleCopyClick}>
                      <p>Наш телефон: +7 921 612-66-11</p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                      <div className='contacts-telefon'  onClick={handleEmailClick}>
                        <p>Наш email: support@remontuvi.ru</p>
                      </div>
                  </div>
                </div>
                <div className='row' style={{display: "none"}}>
                  <div className='col-12'>
                    <a href='#'>
                      <div className='contacts-telefon'>
                          <p style={{ marginRight: '1vw' }}>WhatsApp</p>
                          <img className="imgwhatsapp" src="/img/whatsapp.png" loading="lazy" alt=""/>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='row' style={{display: "none"}}>
                  <div className='col-12'>
                    <a href='#'>
                      <div className='contacts-telefon'>
                        <p style={{ marginRight: '1vw' }}>Telegram</p>
                        <img className="imgwhatsapp" src="/img/telegram.png" loading="lazy" alt=""/>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='row d-flex text-center justify-content-center'>
                  <div className='col-lg-6 col-xl-3 col-md-12 col-sm-12'>
                    <a href='https://kaliningrad.leroymerlin.ru'>
                      <img src='/img/leroy_merlin_logo.png' style={{ width: '130px', height: 'auto', margin: '10px' }}/>
                    </a>
                  </div>
                  <div className='col-lg-6 col-xl-3 col-md-12 col-sm-12'>
                    <a href='http://stroymir39.ru'>
                      <img src='/img/logostroymir.png' style={{ width: '200px', height: 'auto', margin: '10px' }}/>
                    </a>
                  </div>
                  <div className='col-lg-6 col-xl-3 col-md-12 col-sm-12'>
                    <a href='https://master39.net'>
                      <img src='/img/Logo_MasterNEW.png' style={{ width: '130px', height: 'auto', margin: '10px' }}/>
                    </a>
                  </div>
                  <div className='col-lg-6 col-xl-3 col-md-12 col-sm-12'>
                    <a href='https://handyhouse.ru'>
                      <img src='/img/handydors.png' style={{ width: '110px', height: 'auto', margin: '10px' }}/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      {feedbackFormVisible && (
        <div className="feedback-form-overlay">
          <FeedbackForm onClose={() => setFeedbackFormVisible(false)} topicmsg={"общий вопрос"} />
        </div>
      )}
    </div>
  );
}

export default Content4;